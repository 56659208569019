<div class="container">
    <div class="row">
        <div class="card" style="width:100%;">
            <div class="card-body">
                <div *ngIf="linkMyCase">
                    <p>{{'MY_LETTERS_STATIC_TEXT1' | translate }}<a
                        [routerLink]="['/user-profile', 'case-link']">{{'MY_LETTERS_STATIC_TEXT2' | translate}}</a> {{'MY_LETTERS_STATIC_TEXT3' | translate}}
                </p>
                </div>
                <p>{{'MY_LETTERS_STATIC_TEXT4' | translate }}</p>
                <div> <strong>{{'NOTE' | translate }} </strong> {{'MY_LETTERS_STATIC_TEXT5' | translate }}</div>
                <p class="mt-3">{{'MY_LETTERS_STATIC_TEXT6' | translate }}
                    <nh-popover class="pl-1"
                        content="{{'MY_LETTERS_STATIC_TEXT7' | translate }}">
                    </nh-popover>
                </p>
                <form [formGroup]="search">
                            <nh-field label="{{'TYPE_OF_LETTER' | translate}}" [isRow]="true" [mandatory]="false" class="label-section">
                                <select nh name="type" aria-label="type" [items]="items" formControlName="type" bindLabel="value"
                                    bindValue="code"></select>
                            </nh-field>
                        <div class="form-row">
                        <div class="col-md-6">
                            <nh-field label="{{'BEGIN_DATE' | translate }}" [isRow]="true" [mandatory]="false" class="label-section">
                                <nh-datepicker>
                                    <input type="text" name="beginDate" class="form-control" formControlName="beginDate"
                                        (change)="setValidator()" bsDatepicker nh>
                                </nh-datepicker>
                            </nh-field>
                        </div>
                        <div class="col-md-6">
                            <nh-field label="{{'END_DATE' | translate }}" [isRow]="true" [mandatory]="false" class="label-section">
                                <nh-datepicker>
                                    <input type="text" name="endDate" class="form-control" formControlName="endDate"
                                        (bsValueChange)="compareTwoDates()" (change)="compareTwoDates()" bsDatepicker nh>
                                </nh-datepicker>
                            </nh-field>
                          </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                        <button id="continue" [disabled]="error.isError" class="btn btn-secondary btn-search" (click)="searchData()">{{'SEARCH' | translate }} <i
                                class="fa fa-search ml-2"></i></button>
                        </div>
                    </div>
                </form>
                <div class="mt-3" *ngIf="rows">
                    <div class="d-none d-lg-block">
                        <h5 class="label-section"><strong>{{'DISPLAYING' | translate }} {{start}}-{{end}} {{'OF' | translate }} {{rows.length}}
                                {{'NOTICE' | translate }}</strong></h5>
                        <ngx-datatable class='bootstrap material' #table [rows]='rows' [columnMode]='"force"'
                            [headerHeight]='50' [footerHeight]='50' [rowClass]="getRowClass" [rowHeight]='"auto"'
                            [limit]="5" (page)="onPage($event)" [messages]="{emptyMessage: this.emptyMessage}">
                            <ngx-datatable-column name="{{'Letter Name' | translate}}" [width]="250">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div class="letter-name-wrapper">
                                        <img src="../../assets/images/Icons/Open_Letter.svg" alt="close-icon" height="20px" *ngIf="row.viewed == 'Yes'"/>
                                        <img src="../../assets/images/Icons/Close_Letter.svg" alt="close-icon" height="20px" *ngIf="row.viewed == 'No'"/>
                                        <div class="letter-name">
                                            {{row.letterName}}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Letter For' | translate }}" [width]="100">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div>
                                        {{row.letterFor}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'DATE' | translate }}" [width]="100">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div>
                                        {{row.date}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Letter Viewed?' | translate }}" [width]="50" headerClass="text-center">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div class="text-center">
                                        {{row.viewed}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                           <!-- <ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns">
                            </ngx-datatable-column>-->
                            <ngx-datatable-column name="{{'View' | translate }}" [width]="50" headerClass="text-center">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div class="p-0 hover-red text-center">
                                    <a class="p-0 text-left hover-red btn btn-link"
                                        (click)="printPdf(row.fID,row.indivID,row.reqSeq)"><i
                                            class="fa fa-search fa-lg"></i></a>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                        <button *ngIf="rows.length && isAppealLetter && !isFromPartnerPortal" id="myAppeals" class="btn btn-block btn-primary mt-3 ml-0" (click)="goToMyAppeals()">
                            {{'MY_APPEALS' | translate}}
                          </button>
                    </div>
                    <div class="nh-table-mobile mt-3 pt-2 d-lg-none">
                    <div class="table-row mb-3 pb-3" *ngFor="let row of rows; index as i"  [ngClass]="{'row-highlight': getRowClass(row) == 'row-highlight' }"> 
                            <div>
                                <div class="mobile-table-header">{{'Letter Name' | translate }}</div>
                                <hr>
                                <div class="letter-name-wrapper">
                                    <img src="../../assets/images/Icons/Open_Letter.svg" alt="close-icon" height="20px" *ngIf="row.viewed == 'Yes'"/>
                                    <img src="../../assets/images/Icons/Close_Letter.svg" alt="close-icon" height="20px" *ngIf="row.viewed == 'No'"/>
                                    <div class="letter-name">
                                        {{row.letterName}}
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div *ngFor="let head of columns">
                                <div class="mobile-table-header" [innerHtml]="head.name | translate| sanitize"></div>
                                <hr>
                                <div class="mobile-table-data"
                                    [innerHTML]="row[head.prop] | sanitize">
                                </div>
                                <br>
                            </div>
                            <button class="btn btn-sm btn-secondary w-100"
                                (click)="printPdf(row.fID,row.indivID,row.reqSeq)">
                                <span class="pr-2">{{'View' | translate }}</span>
                            </button>
                            <button *ngIf="isAppealLetter && !isFromPartnerPortal" id="myAppeals" class="btn btn-sm btn-primary mt-3 appeal-btn w-100" (click)="goToMyAppeals()">
                                {{'MY_APPEALS' | translate}}
                              </button>
                        </div>
                        <p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
